@use "../../app/styles/common/var";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.content {
  text-align: center;
}

.notFoundTitle {
  font-size: 200px;
  font-weight: 600;
  color: var.$bg-header;
  margin: 0;
  padding: 0;
}

.notFoundSubTitle {
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  color: var.$bg-header;
  padding-block-end: 60px;
}

.notFoundText {
  display: block;
  margin: 0 auto;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
  max-width: 56%;
  font-weight: 600;
  line-height: 22px;
  padding-block-end: 4px;
}

.linkText {
  font-weight: 600;
  font-size: 1.2rem;
  color: black;
  color: rgba(0, 0, 0, 0.8);
}
.linkGoHome {
  color: var.$bg-header;
  letter-spacing: 0.5px;
  padding-inline-start: 5px;
  &:hover {
    text-decoration: underline;
  }
}
