
$bg-header: #5664d2;
$bg-content: #f4f6f8;
$border-card: #0000001e;
$side-menu-active: #5664D2;
$side-menu-default: #6B778C;

$bg-header-info-card: #5664d2;
$header-info-card-text: #fff;
$orange-color: #ff6a59;
